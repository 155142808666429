import styled, { css, keyframes } from 'styled-components';
import { ASSET_PATH } from '../../assetsPath';

const styledPadding = css((props) => (
	props.padding ?
		{
			padding: props.padding
		} :
		{
			paddingBottom: (props.bottom_P ? props.bottom_P : 0) + 'px',
			paddingTop: (props.top_P ? props.top_P : 0) + 'px',
			paddingLeft: (props.left_P ? props.left_P : 0) + 'px',
			paddingRight: (props.right_P ? props.right_P : 0) + 'px'
		}
));

const styledMargin = css((props) => (
	props.margin ?
		{
			margin: props.margin
		} :
		{
			marginBottom: (props.bottom_M ? props.bottom_M : 0) + 'px',
			marginTop: (props.top_M ? props.top_M : 0) + 'px',
			marginLeft: (props.left_M ? props.left_M : 0) + 'px',
			marginRight: (props.right_M ? props.right_M : 0) + 'px'
		}
));

export const Body = styled.div`
    background: #fff none repeat scroll 0 0;
    font-family: 'lato', Arial, Helvetica, sans-serif;
    font-size: 16px;
    color: #4a4a4a;
    width: 100%;
    display: flex;
    margin: 0px;
    margin-top: ${(props) => props.top_M ? props.top_M : 0}px;
    padding: 0px;
    background: ${(props) => props.toShowBg ? 'linear-gradient(to bottom, #fff 0%, #fff 500px, #461f3a 500px, #461f3a 100%)' : '#fff'};

    @media (max-width: 768px) {
        border-top: solid 1px rgba(205,205,205,1);
        background: ${(props) => props.toShowBg ? 'linear-gradient(to bottom, #f9f9f9 0%, #f9f9f9 450px, #461f3a 450px, #461f3a 100%)' : '#fff'};
        margin-top: 0;
        padding-top: ${(props) => props.top_M ? props.top_M : 0}px;
    }
`;

export const Container = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: ${(props) => props.isFlex ? 'flex' : 'block'};
		justify: center;
    flex-direction: ${(props) => props.dir ? props.dir : 'row'};

    @media (max-width: 768px) {
      flex-direction:${(props) => props.mobileDir || 'row'};
    }
    .custom-scroll {
      webkit-overflow-scrolling: touch;
			overflow-y: auto;
			&.noshow{
				::-webkit-scrollbar,  ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track {
					width: 0px;
					height: 0;
				}
			}
    }
		.ellipses {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			display: inline-block;
		}
`;

export const ResponsiveContainer = styled(Container)(
	({ isNoBTPadd, top_M, bottom_M, top_P, bottom_P, justify, wrap, theme: { down, breakpoints } }) => `
        ${down(breakpoints.tb)} {
            padding-left: 20px;
            padding-right: 20px;
        }
        ${down(breakpoints.md)} {
          padding-left: 20px;
          padding-right: 20px;
      }
      justify-content: ${justify || 'unset'};
      flex-wrap: ${wrap || 'unset'};
      padding-top: ${isNoBTPadd ? '0' : top_P || '50'}px;
      margin-top: ${top_M ? top_M : '0'}px;
			margin-bottom:  ${bottom_M ? bottom_M : '0'}px;
      padding-bottom: ${isNoBTPadd ? '0' : bottom_P || '50'}px;
      `
);

export const GenericDiv = styled.div`
    font-size: ${(props) => props.fsize ? props.fsize : 16}px;
    width: ${(props) => props.$width ? props.$width : 'auto'};
		max-width: ${(props) => props.maxWidth ? props.maxWidth : 'auto'};
    position: ${(props) => props.position ? props.position : 'static'};
    box-shadow: ${(props) => props.boxShadow || 'none'};
    border-radius: ${(props) => props.bdrRad || '0'};
		border: ${(props) => props.bdr || '0'};
    overflow: ${(props) => props.overflow ? props.overflow : 'initial'};
	  color: ${(props) => props.color ? props.color : 'inherit'};
		background: ${(props) => props.$bg ? props.$bg : 'initial'};
		font-weight: ${(props) => props.f_weight ? props.f_weight : 'initial'} ;
		height: ${(props) => props.height ? props.height : 'auto'};
		flex : ${(props)=>props.flex ? props.flex : '0 1 auto'};
		z-index: ${(props) => props.$zIndex ? props.$zIndex : 'initial'};
		top: ${(props) => props.$top ? props.$top : 'initial'};
		left: ${(props) => props.$left ? props.$left : 'initial'};
		${styledPadding};
    ${styledMargin};
`;

export const CentreAligDiv = styled(GenericDiv)`
    display: flex;
    justify-content: center;
`;

export const VerticalCentreAligDiv = styled(GenericDiv)`
    display: flex;
    align-items: center;
`;

export const SpaceBetweenDiv = styled(GenericDiv)`
    display: flex;
    justify-content: space-between;
`;

export const FlexDiv = styled(GenericDiv)`
		display: flex;
		flex-direction: ${(props) => props.$direction ? props.$direction : 'row'};
		flex-wrap: ${(props) => props.wrap ? props.wrap : 'unset'};
		justify-content: ${(props) => props.justify ? props.justify : 'center'};
		align-items: ${(props) => props.align ? props.align : 'stretch'};
		position: ${(props) => props.position ? props.position : 'static'};
		height: ${(props) => props.height ? props.height : 'auto'};
		width: ${(props) => props.$width ? props.$width : '100%'};
		cursor: ${(props) => props.$cursor ? props.$cursor : 'auto'};
		max-height: ${(props) => props.max_height ? props.max_height : 'none'};

		@media (max-width: 768px) {
				flex-direction: ${(props) => props.directionInMobile ? props.directionInMobile : 'row'};
		}
`;

export const GenericP = styled.p`
    font-family: 'lato', Arial, Helvetica, sans-serif;
    font-size:${(props) => props.fsize ? props.fsize : '14px'} ;
    font-weight: ${(props) => props.f_weight ? props.f_weight : 'initial'} ;
    flex:${(props) => props.flex ? props.flex : 'none'};
    text-align:${(props) => props.text_align ? props.text_align : 'left'};
    color:${(props) => props.color ? props.color : 'inherit'};
    margin-bottom: ${(props) => props.bottom_M ? props.bottom_M : 0}px;
		line-height: ${(props) => props.$lHeight ? props.$lHeight : 'initial'};
		cursor: ${(props) => props.$cursor ? props.$cursor : 'auto'};
		${styledPadding};
    ${styledMargin};
`;

export const GenericSpan = styled.span`
    font-family: 'lato', Arial, Helvetica, sans-serif;
    font-size:${(props) => props.fsize ? props.fsize : '14px'};
    flex:${(props) => props.flex ? props.flex : 'none'};
    text-align:${(props) => props.text_align ? props.text_align : 'left'};
    color:${(props) => props.color ? props.color : 'black'};
    margin-bottom: ${(props) => props.bottom_M ? props.bottom_M : 0}px;
    margin-top: ${(props) => props.top_M ? props.top_M : 0}px;
    margin-left: ${(props) => props.left_M ? props.left_M : 0}px;
    margin-right: ${(props) => props.right_M ? props.right_M : 0}px;
    padding-bottom: ${(props) => props.bottom_P ? props.bottom_P : 0}px;
    padding-left: ${(props) => props.left_P ? props.left_P : 0}px;
    padding-right: ${(props) => props.right_P ? props.right_P : 0}px;
    padding-top: ${(props) => props.top_P ? props.top_P : 0}px;
    font-weight: ${(props) => props.f_weight ? props.f_weight : 'inherit'};
    position: ${(props) => props.position ? props.position : 'static'};
		vertical-align: ${(props) => props.valign ? props.valign : 'baseline'};
		pointer-events: ${(props) => props.pevents ? props.pevents : ''};
		font-style: ${(props) => props.f_style ? props.f_style : 'inherit'};
		cursor:  ${(props) => props.$cursor ? props.$cursor : 'auto'};
		text-transform: ${(props)=>props.transform ? props.transform : 'none'};
		top: ${(props) => props.$top ? props.$top : '0'};
		right: ${(props) => props.$right ? props.$right : '0'};
		height: ${(props) => props.$height ? props.$height : 'auto'};
		width: ${(props) => props.$width ? props.$width : 'auto'};
		opacity: ${(props) => props.$opacity ? props.$opacity : '1'};
		transform: ${(props) => props.$transform ? props.$transform : 'none'};
		border: ${(props) => props.$border ? props.$border : 'none'};
		background: ${(props) => props.$bg ? props.$bg : 'none'};
		border-width: ${(props) => props.$borderWidth ? props.$borderWidth : '0'};
		line-height: ${(props) => props.$lHeight ? props.$lHeight : 'initial'};
`;

export const Title = styled.p`
  font-size:16px;
  color:#4a4a4a;
  font-weight:700;
  margin-bottom:${(props) => props.marBtm ? props.marBtm : ''};
  margin-top:${(props) => props.marTop ? props.marTop : ''};
`;

export const SpriteIcon = styled.span`
  width: 20px;
  height: 20px;
  background: url(${ASSET_PATH.IMAGES}InvoiceSprite1.png) no-repeat; 
  background-size: 150px 100px;
  display: inline-block;
`;

export const TickMark = styled.span`
	width: 10px;
	height: 10px;
	content: "";
	display: inline-block;
	background: url(${ASSET_PATH.IMAGES}InvoiceSprite1.png) no-repeat;
	background-size: 150px 100px;
	background-position: 0 -65px;
	position: relative;
	left: 4px;
	top: 1px;
`;

export const InfoIcon = styled(SpriteIcon)`
  width: 19px;
  height: 19px;
  background-position: -44px -25px;
  margin-left: 5px;
  vertical-align: ${(props) => props.align || 'middle'};
  cursor:pointer;
`;
export const SortArrow = styled(SpriteIcon)`
  width: 10px;
  height: 11px;
  background-position: -25px -26px;
  position: relative;
  top: 2px;
  left: 2px;
`;
export const HotelIconStyle = styled(SpriteIcon)`
  width: 20px;
  height: 20px;
  background-position:1px -39px;
`;
export const FlightIconStyle = styled(SpriteIcon)`
  width: 19px;
  height: 13px;
  background-position: -21px -45px;
`;
export const SeachIcon = styled(SpriteIcon)`
  width: 15px;
  height: 15px;
  position: absolute;
  background-position: -65px -3px;
  left: ${(props) => props.$left || '10px'};
  top: ${(props) => props.$top || '15px'} ;

	${(props) => props.redIcon && css`
		background: url(${ASSET_PATH.IMAGES}/travreqform/travreq.png) no-repeat; 
		background-size: 200px 650px;
		background-position: -176px -595px;
		width: 24px;
		height: 24px;
		display: inline-block;`}

	${(props) => props.$whiteIcon && css`
			width: 16px;
			height: 16px;
			background-position: -133px -43px;
		`}

`;
export const CloseModal = styled.span`
  position: ${(p) => p.pos || 'absolute'};
  right: 30px;
  top: 30px;
	cursor:pointer;
	z-index: 1;
	@media (max-width: 768px) {
    top: 0;
		right: 0;
		padding: 15px;
  }
`;

export const CloseModalIcon = styled(SpriteIcon)`
	width: 17px;
	height: 17px;
  background-position: -44px -3px;
	display: inline-block;
`;

export const CrossIcon = styled(SpriteIcon)`
  width: 13px;
  height: 13px;
  background-position: -27px -2px;
`;

export const CrossIconText = styled.span`
	cursor: pointer;
	position: relative;
	display: inline-block;
  width: 2em;
  height: 2em;
	font-size: ${(p) => p.fsize || 'initial'};
  &::after, &::before {
		position: absolute;
		left: 1em;
		top: 0;
		content: ' ';
		height: 2em;
		width: 0.15em;
		min-width: 2px;
		background-color: ${(p) => p.color || '#333'};
	}
	&:before {
		transform: rotate(45deg);
	}
	&:after {
  	transform: rotate(-45deg);
	}
`;

export const DownloadIcon = styled(SpriteIcon)`
  width: 21px;
  height: 21px;
`;
export const HeadingWrapper = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: flex-end;
  width:${(props) => props.headingWidth ? props.headingWidth : ''};
  padding-bottom: 15px;
	${(props) => props.active && css`
    position: relative;
    z-index: 2;`
}
  & a{
    color:#4a4a4a;
    font-size:12px;
    border-bottom:1px dashed #4a4a4a;
    margin-right: 10px;
  }
	@media (max-width: 700px) {
		padding: 0 15px;
	}
`;

export const MainWrapper = styled.main`
	padding-bottom: ${(props) => (props.bottom_P ? props.bottom_P : '50px')};
  font-family: 'lato', Arial, Helvetica, sans-serif;
  background: ${(props) => (props.bgColor ? props.bgColor : 'none')};
  min-height: ${(props) => (props.minHt ? props.minHt : 'initial')};
  .custom-scroll {
		overflow-y: auto;
    webkit-overflow-scrolling: touch;
		&.noshow{
			::-webkit-scrollbar,  ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track {
				width: 0px;
				height: 0;
    	}
		}
  }
	.ellipses {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		display: inline-block;
	}
	.hover-list-item {
		transition: background-color 0.3s;
		* {
				cursor: pointer;
		}
		&:hover {
				background-color: rgba(241,117,49,0.03);
		}
		&.orgDetails{
			.anchor {
    	scroll-margin-top: 300px;
		}
		}
	}
`;
export const BgSection = styled.div`
  background: ${(props) => props.bgColor ? props.bgColor : ''};
  min-height: ${(props) => props.minHt ? props.minHt : ''};
`;
export const InnerWrapper = styled.section`
  width:1200px;
  margin: ${(props) => props.margin ? props.margin : '0 auto'};
  color: #4a4a4a;
  padding:${(props) => props.paddingHr ? props.paddingHr : ''};
	height:${(props) => props.height ? props.height : 'auto'};
  display:${(props) => props.makeFlex ? 'flex' : ''};
  justify-content:${(props) => props.spaceBetween ? 'space-between' : ''};
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
  & .onBoardingTooltipWrapper{
    display:none;
  }
  & .stepActiveWrapper{
    position: relative;
    z-index: 11;
    & .disableWrapper{
      pointer-events: none;
  }
  & .onBoardingTooltipWrapper{
    display:block;
  }
}
`;

export const LeftSection = styled.div`
  width: ${(p) => p.$width || '210px'};
  @media (max-width: 768px) {
    width: 100%;
		display: ${(p) => p.mobShow ? 'block' : 'none'};
  }
`;
export const RightSection = styled.div`
	width: ${(p) => p.$width || 'calc(100% - 250px)'};
  @media (max-width: 768px) {
    width: 100%;
		display: ${(p) => p.mobHide ? 'none' : 'block'};
  }
`;
export const Loader = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
`;
export const checkmarkOuter = styled.p`
  display: inline-flex;
  position: relative;
  min-height: 18px;
  flex-shrink: 0;
  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
    opacity: 0;
  }
  
  [type="checkbox"]:checked+span,
  [type="checkbox"]:not(:checked)+span {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-flex;
  }
  
  [type="checkbox"]:checked+span:before,
  [type="checkbox"]:not(:checked)+span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #9b9b9b;
    background: #fff;
		border-radius:${(p) => p.type == 'circle' ? '50%' : '2px'};
  }
  
  [type="checkbox"]:checked+span:before {
    border: 1px solid #f17531;
    content: '';
    width: 18px;
    height: 18px;
    background: #f17531;
    position: absolute;
    top: 0;
    left: 0;
		border-radius:${(p) => p.type == 'circle' ? '50%' : '2px'};
    transition: all 0.2s ease;
  }

  [type="checkbox"]:checked+span:after {
    position: absolute;
    left: 6px;
    top: 3px;
    content: '';
    display: block;
    width: 6px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  
`;

export const radioOuter = styled.p`
  display: inline-flex;
  &[type="radio"]:checked,
  &[type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  &[type="radio"]:checked + label,
  &[type="radio"]:not(:checked) + label
  {
      position: relative;
      padding-left: 20px;
      cursor: pointer;
      line-height: 20px;
      display: inline-flex;
  }
  &.lableBlock [type="radio"]:checked + label, &.lableBlock [type="radio"]:not(:checked) + label {
    display: initial !important;
  }
  &[type="radio"]:checked + label:before {
    border: 1px solid #f17531;
  }
  &[type="radio"]:checked + label:after,
  &[type="radio"]:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: #f17531;
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
  }
  &.lableBlock [type="radio"]:checked + label:after, &.lableBlock [type="radio"]:not(:checked) + label:after {
      top: 50%;
      margin-top: -6px;
  }
  &[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  &[type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
  }
`;

export const ripple = styled.div`
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: .3;
    transition: 0s;
  }
`;
export const TopFloatingSection = styled(GenericDiv)`
  min-height: ${(p) => p.minHt || '100'}px;
  margin-top: ${(props) => props.marginTop ? props.marginTop : '-100px'};
  margin-bottom:  ${(props) => props.marginBottom ? props.marginBottom : '40px'};
  position:  ${(props) => props.position ? props.position : ''};
  top: ${(props) => props.$top ? props.$top : ''};
  z-index: ${(props) => props.zIndex ? props.zIndex : ''};

  ${(props) => !props.noStyle && css`
    box-shadow: ${props.shadow || '0 3px 30px rgba(0,0,0,0.1)'};
    background: ${props.$bg || '#fff'};
    border-radius: ${props.bdrRad || '5px'};
    border: ${props.bdr || '1px solid #ddd'};
  `
}
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const EditPolicySection = styled.div`
  border: 1px solid #dddddd;
  border-radius: 5px;
  background: #fff;
  margin-top: -106px;
  margin-bottom: 3px;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.2);
`;
export const ClickText = styled.span`
  color: ${(props) => props.color || '#0084ff'};
  font-size: ${(props) => props.f_size || '12'}px;
	font-weight: ${(props) => props.f_weight || 'normal'};
  margin-left: ${(props) => props.left_M || '0'}px;
  cursor: ${(props) => props.$cursor || 'pointer'};
	pointer-events: ${(props) => props.pevent || ''};
	a {
		color: inherit;
		font-size: inherit;
		&:hover {
			color: inherit;
			text-decoration: none;
		}
	}
`;
export const SubTitle = styled.h4`
  font-weight: 900;
  font-size: ${(props) => props.fSize ? props.fSize : '18px'};
  color: ${(props) => props.color ? props.color : 'inherit'};
  margin-bottom: ${(props) => props.bottom_M ? props.bottom_M : 0}px;
  margin-top: ${(props) => props.top_M ? props.top_M : 0}px;
  margin-left: ${(props) => props.left_M ? props.left_M : 0}px;
  margin-right: ${(props) => props.right_M ? props.right_M : 0}px;
  padding-bottom: ${(props) => props.bottom_P ? props.bottom_P : 0}px;
  padding-left: ${(props) => props.left_P ? props.left_P : 0}px;
  padding-right: ${(props) => props.right_P ? props.right_P : 0}px;
  padding-top: ${(props) => props.top_P ? props.top_P : 0}px;
`;

export const SectionTitle = styled.h3`
  color:#000;
  font-size:24px;
  font-weight:700;
  margin-bottom:20px;
`;

export const SubText = styled.span`
    font-size: 14px;
    color: #9b9b9b;
    margin-left: 5px;
    font-weight: 400;
`;

export const TopSection = styled.div`
padding:0 20px;
margin-bottom:30px;
`;

export const PaginationWrapper = styled.div`
    display:flex;
    justify-content:space-between;
`;

export const FeatureWrapper = styled.div`
  ${(props) => props.active && css`
    position: relative;
    z-index: 2;`
}
`;

export const ActionText = styled.span`
    font-size: ${(props) => props.f_size || '14'}px;
    font-weight: ${(props) => props.weight ? props.weight : 'normal'};
    color: ${(props) => props.color ? props.color : '#f17531'};
		margin-left:${(props) => props.left_M || '0'}px;
		margin-right:${(props) => props.right_M || '0'}px;
    cursor: pointer;
`;

export const ErrorText = styled.span`
  margin:  ${(props) => props.margin || '0'};
  white-space: ${(props) => props.whiteSpace || 'inherit'};
  padding:  ${(props) => props.padding || '0'};
  font-size: ${(props) => props.f_size || '12'}px;
  font-weight: ${(props) => props.weight ? props.weight : 'normal'};
  color: #EB2026;
	@media (max-width: 768px) {
    padding: 15px;
  }
`;

export const dashedLink = styled.span`
    color: #4a4a4a;
    font-size: 12px;
    border-bottom: 1px dashed #4a4a4a;
    margin-right: 10px;
    cursor: pointer;
`;

export const FadeIn = keyframes`
    0% {opacity:0.3;}
    100% {opacity:1;}
`;

export const TooltipContainer = styled(GenericSpan)`
  position: relative;
  .tooltip-content,.tooltip-wrap {
    display: none;
  }
  &:hover {
    .tooltip-content,.tooltip-wrap {
      display: block;
    }
  }
`;
export const DisplayFlex = styled.span`
    display: flex;
`;

export const FlexType = styled.span`
   flex: ${(props) => props.flex ? props.flex : '1'};
`;

export const SaveBtnWrapper = styled.span`
    width: 80px;
    display: inline-block;
    margin-right:10px;
     &:hover{
        background:#fdf1ed;
  }
`;
export const SaveCloseBtnWrapper = styled.span`
    width: 130px;
    display: inline-block;
`;

export const UtilitySpriteIcon = styled.span`
	background:url(${ASSET_PATH.IMAGES}tripTagSprite.png) no-repeat; background-size:150px 100px;
	&.deleteIcon{
		width:16px;
		height:18px;
		display:inline-block;
		background-position: -2px -51px;
		margin-right: ${(props) => props.marRt ? props.marRt : '15px'};
	}
	&.deleteIconGrey{
		width:16px;
		height:18px;
		display:inline-block;
		background-position:-22px -51px;
		margin-right: ${(props) => props.marRt ? props.marRt : '15px'};

	}
	&.editIcon{
		width: 15px;
		height: 15px;
		display: inline-block;
		background-position: -41px -53px;
		margin-right: ${(props) => props.marRt ? props.marRt : '15px'};
	}
	&.editIconGrey{
		width: 15px;
		height: 15px;
		display: inline-block;
		background-position: -62px -53px;
		margin-right: ${(props) => props.marRt ? props.marRt : '15px'};

	}
	&.backArrow{
		width: 16px;
		height: 18px;
		display: inline-block;
		background-position: 0px -71px;
		margin-right: ${(props) => props.marRt ? props.marRt : '15px'};
		margin-top: 6px;
		cursor: pointer;
	}
	&.eyeIcon{
		width: 25px;
		height: 16px;
		display: inline-block;
		background-position: -23px -73px;
		margin-right: ${(props) => props.marRt ? props.marRt : '10px'};

	}

	&.chkBoxIcon{
		width: 20px;
		height: 20px;
		display: inline-block;
		background-position:-84px -30px;
		margin-right: ${(props) => props.marRt ? props.marRt : '10px'};

	}
	&.dropDownArrow{
		width: 20px;
		height: 20px;
		display: inline-block;
		background-position: -84px -50px;
		margin-right: ${(props) => props.marRt ? props.marRt : '10px'};

	}
	&.radioBtnIcon{
		width: 20px;
		height: 20px;
		display: inline-block;
		background-position:-84px -69px;
		margin-right: ${(props) => props.marRt ? props.marRt : '10px'};

	}
	&.attachementIcon{
		width: 20px;
		height: 25px;
		display: inline-block;
		background-position: -89px -1px;
		vertical-align:middle;
		margin-right: ${(props) => props.marRt ? props.marRt : '10px'};
	}
	&.CloseModal{
		width: 17px;
		height: 17px;
		position: absolute;
		display: inline-block;
		background-position: -44px -3px;
		right: 30px;
		cursor:pointer;
	}
	&.freeTxtIcon{
		width: 19px;
		height: 19px;
		display: inline-block;
		background-position: -114px -2px;
		margin-right: ${(props) => props.marRt ? props.marRt : '10px'};
	}
	&.downloadIcon{
		width: 17px;
		height: 17px;
		display: inline-block;
		background-position: -3px -3px;
		cursor:pointer;
	}
`;

export const DropDownArrow = styled.i`
  border: ${(props) => props.border ? props.border : 'solid #ff674b'};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  position: absolute;
  right: ${(props) => props.right ? props.right : '0'};
  top: ${(props) => props.$top ? props.$top : '0'};
  &:hover {
    transform: ${(props) => props.shouldHover ? 'rotate(225deg)' : ''};
    top: ${(props) => props.shouldHover ? '2px' : ''};
  }
  &.open_state{
    transform: rotate(225deg);
  }
`;

export const TransparentLayer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    z-index: ${(props) => props.zIndex ? props.zIndex : '1'};
`;

export const TopHead = styled.div`
  background: ${(props) => props.bgColor ? props.bgColor : '#efefef'};
  min-height: ${(props) => props.minHt ? props.minHt : '93px'};
`;

export const MenuItem = styled.li`
    display: flex;
    align-items: center;
    padding: ${(props) => (props.padding ? props.padding : '4px')};
    font-size: 16px;
    cursor: pointer;
    border-radius: ${(props) => (props.isActive ? '24px' : '4px')};
    color: ${(props) => (props.isActive ? '#351e2e' : '#4a4a4a')};
    font-weight: ${(props) => (props.isActive ? '900' : 'normal')};
    opacity: ${(props) => (props.isActive ? 1 : 0.5)};
    background-color: ${(props) => (props.isActive ? '#fdf0ec' : '')};
`;

export const Icon = styled(FlexDiv)`
    width: 30px;
    height: 30px;
    margin-right: 14px;
`;

export const AngleArrow = styled.i`
  border: solid #ff674b;
  border-color: ${(p) => p.color || '#ff674b'};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 0.2em;
  transform: rotate(${(p) => p.left ? '135deg' : (p.up ? '-135deg' : (p.down ? '45deg' : '-45deg'))});
  position: absolute;
  right: ${(p) => p.pos_right ? p.pos_right : '0'};
	top: ${(p) => p.$top ? p.$top : '0'};
	cursor: pointer;
`;

export const Hamburger = styled.div`
  position: relative;
  background-color: ${(props) => props.bgColor ? props.bgColor : 'rgb(17, 17, 17)'};
  height: 2px;
  width: 20px;
  &:before, &:after {
    content: '';
    position: absolute;
    background-color: ${(props) => props.bgColor ? props.bgColor : 'rgb(17, 17, 17)'};
    height: 2px;
    width: 100%;
    top: 6px;
		right:0;
  }
  &:after {
    top: -6px;
  }
`;

export const Card = styled.div`
    position: relative;
    border-radius: ${(p) => p.radius ? p.radius : '10px'};
    box-shadow: ${(p) => p.boxShadow ? p.boxShadow : ' 0 3px 30px 0 rgba(0, 0, 0, 0.1)'};
    background-color: #ffffff;
    margin: ${(p) => p.boxMargin ? p.boxMargin : '0 0 20px 0'};
    padding: ${(p) => p.boxPadding ? p.boxPadding : '30px 40px'};
		z-index: ${(p) => p.zIndex ? p.zIndex : 'auto'};
		border: ${(p) => p.border ? p.border : 'none'};
`;

export const PrimaryButton = styled.button`
    width: 30%;
    border: none;
    height: 48px;
    border-radius: 42px;
    background-image: linear-gradient(95deg, #f0772c, #f95776);
    color: #fff;
    letter-spacing: 0.3px;
    font-weight: bold;
    cursor: pointer;
    font-size: ${(props) => props.font_size ? props.font_size : '16px'};
    margin-top : 1%;
    margin-left : 65%;
    @media (max-width: 767px) {
			margin-left : 35%;
			width:60%;
	};
`;

export const DotsIcon = styled.div`
    position: ${(props) => props.position ? props.position : 'static'};
    right: ${(props) => props.right ? props.right : 'auto'};
    width: 30px;
    height: 25px;
    background-image: radial-gradient(circle, #999999 2px, transparent 1px);
    background-size: 100% 33.33%;
		cursor: pointer;
		&.disable{
			cursor: not-allowed;
		}
`;
export const Headings = styled.h1`
  color: ${(p) => p.color || '#000'};
  line-height: normal;
  ${(props) => {
		switch (props.as) {
			case 'h1':
				return css`
				font-size: ${(p) => p.font_size || '40'}px;
				font-weight: 900;
			`;
			case 'h2':
				return css`
				font-size: ${(p) => p.font_size || '32'}px;
				font-weight: 900;
			`;
			case 'h3':
				return css`
				font-size: ${(p) => p.font_size || '24'}px;
				font-weight: ${(p) => p.f_weight || '700'};
			`;
			case 'h4':
				return css`
				font-size: ${(p) => p.fontSize || '16'}px;
				color: ${(p) => p.color || '#4a4a4a'};
				font-weight: ${(p) => p.f_weight || '700'};
			`;
			default:
				return css`
				font-size: ${(p) => p.font_size || '36'}px;
				font-weight: ${(p) => p.f_weight || '900'};
			`;
		}
	}}
`;

export const Heading = styled(Headings)`
    margin-bottom:${(props) => props.marBt ? props.marBt : ''};
    margin-top:${(props) => props.martop ? props.martop : ''};
`;

export const RemiderTextDesc = styled(GenericSpan)`
	@media (max-width: 768px) {
		max-width: calc(100% - 40px) !important;
	}
`;

export const HiddenElem = styled.span`
      height: 0;
      width: 0;
      overflow: ${(prop) => prop.activeModal ? 'visible' : 'hidden'};
`;

export const bgShowAnimate = () => keyframes`
    0% {
			background: rgba(0,0,0,0);
		}
    100% {
			background: rgba(0,0,0,.6);
			}
`;

export const FadeInOut = keyframes`
		0% {opacity:0.05;}
    30% {opacity:1;}
		70% {opacity:1;}
		100% {opacity:0.05;}
`;

export const BounceAnim = (start, end) => keyframes`
	from {
			transform: translateY(${start});
		}
	to {
		transform: translateY(${end});
		}
`;

export const ColoredTick = styled(GenericSpan)`
	content: '';
	font-size: ${(p)=>p.fsize || 10}px;
	width: 1em;
	height: 0.5em;
	border: 0.2em solid ${(p)=>p.color || '#007E7D'};
	border-top-width: 0px;
	border-right-width: 0px;
	display: inline-block;
	transform: rotate(-45deg);
`;

export const FeatureContainer = styled(FlexDiv)`
	${(props) => props.active && css`
		position: relative;
		z-index: 2;`
}
`;
