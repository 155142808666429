import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	.hover-list-item {
		transition: background-color 0.3s;
		* {
				cursor: pointer;
		}
		&:hover {
				background-color: rgba(241,117,49,0.03);
		}
	}
	.orgTxt {
		color: #ff664b;
	}
	.elem-hidden {
		height: 0 !important;
		width: 0 !important;
		overflow: hidden !important;
		margin: 0 !important;
		padding: 0 !important;
	}
	.latoBlack, .latoBold {
		font-weight: 900;
	}
	.yellowbg {
		background-color:#fef5e8;
	}
	.elem-hidden {
		height: 0 !important;
		width: 0 !important;
		overflow: hidden !important;
		margin: 0 !important;
		padding: 0 !important;
	}
	.slick-list,
	.slick-slider,
	.slick-track {
			position: relative;
			display: block;
	}
	.slick-loading .slick-slide,
	.slick-loading .slick-track {
			visibility: hidden;
	}
	.slick-slider {
			box-sizing: border-box;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			-webkit-touch-callout: none;
			-khtml-user-select: none;
			-ms-touch-action: pan-y;
			touch-action: pan-y;
			-webkit-tap-highlight-color: transparent;
	}
	.slick-list {
			overflow: hidden;
			margin: 0;
			padding: 0;
	}
	.slick-list:focus {
			outline: 0;
	}
	.slick-list.dragging {
			cursor: pointer;
			cursor: hand;
	}
	.slick-slider .slick-list,
	.slick-slider .slick-track {
			-webkit-transform: translate3d(0, 0, 0);
			-moz-transform: translate3d(0, 0, 0);
			-ms-transform: translate3d(0, 0, 0);
			-o-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
	}
	.slick-track {
			top: 0;
			left: 0;
			display: flex;
	}
	.slick-track:after,
	.slick-track:before {
			display: table;
			content: "";
	}
	.slick-track:after {
			clear: both;
	}
	.slick-slide {
			display: none;
			height: 100%;
			min-height: 1px;
	}
	[dir="rtl"] .slick-slide {
			float: right;
	}
	.slick-slide img {
			display: block;
	}
	.slick-slide.slick-loading img {
			display: none;
	}
	.slick-slide.dragging img {
			pointer-events: none;
	}
	.slick-initialized .slick-slide {
			display: block;
	}
	.slick-vertical .slick-slide {
			display: block;
			height: auto;
			border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
			display: none;
	} 
	.slick-prev.slick-disabled:before,
	.slick-next.slick-disabled:before {
		opacity: 0.25;
		cursor: not-allowed;
	}
	.ellipses {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		display: inline-block;
		max-width: 100%;
	}
	.tooltip-wrapper {
		cursor: pointer;
		&:hover  {
		.tooltip-wrap {
			display: block;
		}
	}
	}
	.disabled{
		pointer-events: none;
		cursor: not-allowed;
	}
	.custom-scroll {
		scrollbar-color:rgba(0, 0, 0, .3) rgba(255, 255, 255, .5);
  	scrollbar-width: thin;
		webkit-overflow-scrolling: touch;
		-ms-overflow-style : scrollbar;
		&::-webkit-scrollbar {
					width: 6px;
					height: 6px;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: rgba(0, 0, 0, .5);
			box-shadow: 0 0 1px rgba(255, 255, 255, .5);
		}
		&::-webkit-scrollbar-track {
			background-color: rgba(0, 0, 0, .15);
		}
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
		&.hide_scroll {
			::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}
		@media screen and (max-width: 680px) {
			::-webkit-scrollbar {
				width: 4px;
			}
		}
		&.hide_hr-scroll {
			::-webkit-scrollbar {
				width: 4px;
				height: 0;
			}
		}
	}
	hr {
		margin-top:20px;
		margin-bottom:20px;
		border: 0;
		border-top: 1px solid #eee;
	}
`;

export default GlobalStyle;
