import React from 'react';
import qs from 'query-string';
import moment from 'moment';
import axios from 'axios';
import { matchPath, Router } from 'react-router';
import cloneDeep from 'lodash/cloneDeep';
import isPropValid from '@emotion/is-prop-valid';

import {
	Cross, ErrorFld,
	InfoTextWithValidation,
	PasswordValidations,
	Tick
} from 'common/FormField/FormFieldStyles';

import { PATHS, APPLICATION_CONSTANTS, REGEX_PATTERNS, FILTER_CONSTANTS, FILE_TYPE, APPLICATION_PATH } from '../AppConstants';
import { fileUploadConfig, possibleUploadStates } from 'common/FileUploader/config';
import globalData from './globalDataFactory';
import { getReqID } from '../../projectSetups/nodeUtils';

const ADMIN_ROLE = 'ADMIN';

export function setCookie(name, value, days, cookieDomain) {
	if (typeof document === 'undefined') {
		return false;
	}
	let expires = '';
	let domain = '';
	if (days) {
		let date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = '; expires=' + date.toUTCString();
	}
	if (cookieDomain) {
		domain = 'domain=' + cookieDomain;
	}
	document.cookie = name + '=' + (value || '') + expires + '; path=/;' + domain;
}

export function getCookie(name) {
	if (typeof document === 'undefined') {
		return false;
	}
	let nameEQ = name + '=';
	let ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

export function isValidJsObject(val) {
	return val && val !== 'null';
}

export function eraseCookie(name) {
	document.cookie = name + '=; Max-Age=-99999999;';
}

export const urlParam = (name) => {
	if (typeof window === 'undefined') {
		return null;
	}
	if (window.location && window.location.href) {
		let results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
		if (results == null) {
			return null;
		} else {
			return decodeURI(results[1]) || 0;
		}
	}
};

export const numberWithCommas = (x) => {
	if (x) {
		return parseInt(x + '').toLocaleString('en-in');
	}// checked cross browser compatibility
	else {
		return 0;
	}
};

export function validatePAN(panCardNo) {
	if (panCardNo) {
		let panPat = /^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/;
		return panCardNo.search(panPat) !== -1;
	}
	return false;
}

export const isDeviceMobile = () => {
	if (typeof window === 'undefined') {
		return false;
	}
	return !!(window && window.navigator && /Mobi/.test(window.navigator.userAgent));
};

export const createOpenTagScript = () => {
	let head = document.getElementsByTagName('head')[0];
	let script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = '//d3c3cq33003psk.cloudfront.net/opentag-53841-corporatequery.js';
	head.appendChild(script);
};

export const required = (value) => (value ? undefined : 'Required!');
export const phone = (value) => value && /^(0|[1-9][0-9]{9})$/i.test(value) ?
	undefined :
	(value ? 'Invalid phone number, must be 10 digits' : undefined);
export const password = (value) => value && (/^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{8,}$/.test(value.trim()) ? undefined : 'Password is not strong enough');

export const invalidSpecialChar = (value) => value && !/^([A-Za-z0-9@$!%*#?&]+$)/.test(value.trim()) ?
	'Use only @$!%*#?&' : undefined;

export const passLength = (value) => value && (/^(?=.{8,})/.test(value.trim()) ? undefined : 'Minimum of 8 characters is required');
export const email = (value) =>
	value && !/(?=^.{6,80}$)(^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$)/i.test(value.trim()) ?
		'Invalid email address' :
		undefined;

export const isValidPanCard = (value) => value && REGEX_PATTERNS.PAN_REGEX.test(value.trim()) ? undefined : 'invalid pan number';

export const companyName = (value) => value && (value.trim().length > 50) ? 'Company name can’t be longer than 50 characters. Try abbreviating the name if possible' : undefined;

export const isValidGSTNUmber = (value) => value && REGEX_PATTERNS.GST_REGEX.test(value.trim());

export const isValidName = (value) => value && /^[a-zA-Z ]+$/.test(value) ? undefined : (value ? 'invalid name' : undefined);

export const aadharValidator = (value) => value && REGEX_PATTERNS.AADHAR_REGEX.test(value.trim()) ?
	'Invalid Aadhar' : undefined;

export const passportValidator = (value) => value && REGEX_PATTERNS.PASSPORT_REGEX.test(value.trim()) ?
	'Invalid Passport' : undefined;

export const getUrlParamsMap = (searchTerms) => {
	if (!searchTerms) {
		return {};
	}
	return qs.parse(searchTerms, { ignoreQueryPrefix: true });
};

export const findEmail = (records, emailId) => {
	for (let record in records) {
		if (records.hasOwnProperty(record) && records[record]) {
			let recordEmail = records[record]['emailId'];
			if (recordEmail === emailId) {
				return records[record]['status'];
			}
		}
	}
	return null;
};

export function checkCompanyDetailsSubmitted() {
	let hasNotSubmittedCompanyDetails = false;
	let decisionApiRes = this.props && this.props.decisionApiPayload;
	if (this.props.emailId && decisionApiRes &&
		decisionApiRes['empsStatus'] &&
		decisionApiRes['empsStatus'][this.props.emailId]) {
		decisionApiRes = decisionApiRes['empsStatus'][this.props.emailId];
		if (decisionApiRes && (!decisionApiRes['orgInfo'] || (decisionApiRes['orgInfo'] && !decisionApiRes['orgInfo']['doesOtherAdminExist']))) {
			hasNotSubmittedCompanyDetails = (decisionApiRes['empInfo']['checkPoint'] < 3);
		}
	}
	return hasNotSubmittedCompanyDetails;
}

export function checkDocSubmitted() {
	let hasDocSubmitted = false;
	let decisionApiRes = this.props && this.props.decisionApiPayload;
	if (this.props.emailId && decisionApiRes &&
		decisionApiRes['empsStatus'] &&
		decisionApiRes['empsStatus'][this.props.emailId]) {
		decisionApiRes = decisionApiRes['empsStatus'][this.props.emailId];
		hasDocSubmitted = (decisionApiRes['empInfo']['checkPoint'] === 4);
	}
	return hasDocSubmitted;
}

export const ATOB = (stringVal) => {
	let decodedVal = '';
	try {
		decodedVal = atob(stringVal);
	} catch (e) {
		console.log('Cannot decode ' + stringVal);
	}
	return decodedVal;
};

export const loadQubitScript = (path) => {
	try {
		let scriptSrc;
		switch (path) {
			case PATHS.LANDING_PAGE:
				scriptSrc = '//d3c3cq33003psk.cloudfront.net/opentag-53841-corporateregistration.js';
				break;
			case PATHS.SIGNUP_PAGE:
				scriptSrc = '//d3c3cq33003psk.cloudfront.net/opentag-53841-corporateregistrationpage.js';
				break;
			default:
				break;
		}
		if (scriptSrc) {
			const script = document.createElement('script');
			script.async = true;
			script.defer = true;
			script.src = scriptSrc;
			document.body.appendChild(script);
		}
	} catch (error) {

	}
};

export const renderPasswordValidations = (infoObj, value) => {
	if (!infoObj) {
		return null;
	}
	let validationStates = {
		isPasswordHasMinChar: false,
		isPasswordContainsNumberAlphabetSpecialChar: false
	};

	if (value.length >= 8) {
		validationStates.isPasswordHasMinChar = true;
	}
	validationStates.isPasswordContainsNumberAlphabetSpecialChar = password(value) === undefined;

	return (
		<PasswordValidations>
			<InfoTextWithValidation>
				{validationStates.isPasswordHasMinChar ? <Tick /> : <Cross />}
				<span className={validationStates.isPasswordHasMinChar ? 'valid' : 'inValid'}>{infoObj.minChar}</span>
			</InfoTextWithValidation>
			<InfoTextWithValidation>
				{validationStates.isPasswordContainsNumberAlphabetSpecialChar ? <Tick /> : <Cross />}
				<span className={validationStates.isPasswordContainsNumberAlphabetSpecialChar ? 'valid' : 'inValid'}>{infoObj.isValid}</span>
			</InfoTextWithValidation>
		</PasswordValidations>
	);
};

export const renderErrorInfo = (value) => {
	let invalidStatus = invalidSpecialChar(value) != undefined;

	return (
		<div>
			{invalidStatus && <ErrorFld className='error'>Use only @$!%*#?&</ErrorFld>}
		</div>
	);
};

export const serializeObject = (obj) => Object.keys(obj).map((key) => key + '=' + obj[key]).join('&');
export const getFormattedDate = (date, format) => {
	let ret = '';
	try {
		if (typeof date == 'string') {
			date = new Date(date);
		}
		date = moment(date);
		if (date.isValid()) {
			if (format === 'time') {
				ret = date.valueOf();
			} else {
				ret = date.format(format);
			}
		}
	} catch (error) {
		saveServerLogs(error && error.stack ? error.stack : error, 'error');
	}
	return ret;
};

export const getDaysDiff = (start, end) => {
	if (typeof start == 'string') {
		if (isNaN(start)) {
			start = new Date(start);
		} else {
			start = new Date(Number(start));
		}
	}
	if (typeof end == 'string') {
		if (isNaN(end)) {
			end = new Date(end);
		} else {
			end = new Date(Number(end));
		}
	}
	const st = moment(start);
	const en = moment(end);
	return st.diff(en, 'days');
};
export const fullnameValidation = (...args) => {
	const name = args[4];
	const value = args[1];
	const validation = args[0][name].validation;
	let error;
	const allval = { ...args[2] };
	validation.forEach((valid) => {
		const fielderror = valid(value);
		error = typeof fielderror == 'undefined' ? error : fielderror;
	});
	if (args[3].dirty && allval.firstName) {
		const fullname = allval.firstName + allval.lastName;
		let regex = REGEX_PATTERNS.MAXLEN.replace('len', (APPLICATION_CONSTANTS.FULLNAME_LENGTH + 1));
		regex = new RegExp(regex);
		error = (regex.test(fullname.trim())) ? `Full Name should not exceed the maximnum limit of ${APPLICATION_CONSTANTS.FULLNAME_LENGTH} characters` : error;
	}
	return error;
};

const BACK = 8;
const TAB = 9;
const NEXT = 39;
const PREV = 37;
const DELETE = 46;
const ENTER = 13;

export const isNumberKey = (event) => (event.keyCode >= 48 && event.keyCode <= 57) || event.keyCode === 190;

export const isActionKey = (event) => (event.keyCode === BACK || event.keyCode === NEXT || event.keyCode === PREV || event.keyCode === DELETE || event.keyCode === TAB ||
	event.keyCode === ENTER);

export const getUUID = () => {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}
	return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
		s4() + '-' + s4() + s4() + s4();
};
export const getUserServiceParams = () => {
	const mmtAuth = getCookie('mmt-auth');
	const dvid = getCookie('dvid');
	const userIdentifier = {
		'ipAddress': 'ipAddress',
		'imie': 'imie',
		'appVersion': 'appVersion',
		'os': 'DESKTOP',
		'osVersion': 'osVersion',
		'timeZone': 'timeZone',
		'type': 'mmt-auth',
		'Authorization': APPLICATION_CONSTANTS.MMT_USER_SERVICE_AUTHORIZATION,
		'cookie': mmtAuth,
		'value': mmtAuth,
		'deviceId': dvid
	};
	const options = {
		'Authorization': APPLICATION_CONSTANTS.MMT_USER_SERVICE_AUTHORIZATION,
		'User-Identifier': JSON.stringify(userIdentifier)
	};
	const query = JSON.stringify({
		'query': [
			[{
				'name': 'extendedUser',
				'keys': ['primaryEmailId', 'contactDetails', 'personalDetails', 'corporateData']
			}]
		]
	});
	return {
		headers: options,
		query: query
	};
};

export const getDate = (date, format, obj = {}) => {
	let ret = '';
	try {
		if (typeof date == 'string') {
			if (isNaN(date)) {
				date = new Date(date);
			} else {
				date = new Date(Number(date));
			}
		}

		if (!format) {
			format = 'YYYY-MM-DD';
		}
		const diffObj = {
			days: 0,
			months: 0,
			years: 0
		};
		if (obj.days) {
			diffObj.days = obj.days;
		}
		if (obj.months) {
			diffObj.months = obj.months;
		}
		if (obj.years) {
			diffObj.years = obj.years;
		}
		date = moment(date);
		ret = date.isValid() ? date.add(diffObj).format(format) : '';
		if (format === 'time') {
			ret = date.valueOf();
		}
	} catch (error) {
		saveServerLogs(error, 'error');
	}
	return ret;
};

export const getShortMonth = (m) => {
	try {
		return moment.monthsShort(m);
	} catch (error) {
		return m;
	}
};

export const saveServerLogs = (msg, type, head = '') => {
	const xreqId = getXrequestId();
	if (typeof window !== 'undefined' && (typeof isDev === 'undefined' || !isDev)) {
		try {
			const mmuuid = getProfileDataProps('mmtUuid');
			const dvid = getCookie('dvid');
			const windowUrl = typeof window !== 'undefined' && typeof window.location !== 'undefined' ?
				window.location.pathname + window.location.search + window.location.hash : '';
			const data = { msg: `[${windowUrl}] [${mmuuid}:${dvid}:${xreqId}] [${head}] ${msg}`, type };
			const url = APPLICATION_PATH + PATHS.SERVER_LOG;
			axios.post(url, data);
		} catch (error) {
			console.error(error);
		}
	} else if (typeof window === 'undefined') {
		throw new Error(msg);
	} else {
		console.error(msg);
	}
};

export const extractFirstName = (name) => name && name.split(' ')[0];

export const extractDomain = (businessEmailId) => {
	const domain = businessEmailId && businessEmailId.split('@').pop();
	return domain ? '@' + domain : '';
};

export const goToMyBiz = (event) => {
	event.preventDefault();
	window.location = PATHS.MYBIZ_URL;
};

export const goToHome = (event) => {
	event.preventDefault();
	window.location = PATHS.MMT_MYBIZ;
};

export const formatCurrency = (amt, symbol) => {
	try {
		if (amt && !isNaN(amt)) {
			const currArr = Number(amt).toFixed(2).split('.');
			const intAmt = currArr[0].replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
			const decAmt = currArr[1] && Number(currArr[1]) > 0 ? `.${currArr[1]}` : '';
			const sym = symbol ? APPLICATION_CONSTANTS.RUPEE : '';
			return sym + intAmt + decAmt;
		}
		return amt;
	} catch (e) {
		return amt;
	}
};

/**
 * returns default start end date object
 */
export const getDefaultDate = (today = APPLICATION_CONSTANTS.TODAY, dateFormat = APPLICATION_CONSTANTS.DATE_FORMAT, month = false, specific = false) => {
	const filterMonths = getDateFilterKeys(today);
	const fltEnd = today;
	let monthLen = month;
	let fltStartMonth;
	let year = fltEnd.getFullYear();
	let startDate;
	let endDate;
	if (specific === APPLICATION_CONSTANTS.DEFAULT_QTR) {
		const monthArr = getDateFilterKeys(APPLICATION_CONSTANTS.TODAY);
		const monthLength = monthArr.length;
		const currDate = APPLICATION_CONSTANTS.TODAY;
		const currMonth = currDate.getMonth();
		const qtrStart = monthArr[monthLength - 1];
		const year = currMonth > qtrStart ? currDate.getFullYear() : currDate.getFullYear() - 1;
		startDate = getFormattedDate(new Date(year, qtrStart, 1), dateFormat);
		endDate = getFormattedDate(new Date(year, qtrStart + APPLICATION_CONSTANTS.QUARTER, 0), dateFormat);
		return {
			startDate,
			endDate
		};
	} else {
		if (month === false) {
			monthLen = filterMonths.length - 1;
		}
		fltStartMonth = filterMonths[monthLen];
		if (filterMonths[0] < fltStartMonth) {
			year--;
		}
		return {
			startDate: getFormattedDate(`${year}/${fltStartMonth + 1}/1`, dateFormat),
			endDate: getFormattedDate(fltEnd, dateFormat)
		};
	}
};

/**
 * get months value array for date filter
 */
export const getDateFilterKeys = (today) => {
	const currMonth = today.getMonth();
	let currQtrMonths = (currMonth + 1) % APPLICATION_CONSTANTS.QUARTER;
	currQtrMonths = currQtrMonths ? currQtrMonths : APPLICATION_CONSTANTS.QUARTER;
	let qtrStart = currMonth - currQtrMonths - (APPLICATION_CONSTANTS.QUARTER - 1);
	const dateRange = [];
	for (let i = 0; i <= currMonth - qtrStart; i++) {
		let month = currMonth - i;
		month = month >= 0 ? month : month + 12;
		dateRange.push(month);
	}
	return dateRange;
};

export const getDateText = (start, end, showYear) => {
	let text = '';
	try {
		const startDate = moment(new Date(start));
		const endDate = moment(new Date(end));
		text = `${startDate.format('MMM')}${showYear ? `'` + startDate.format('YY') : ''}`;
		if (end) {
			text += ` - ${endDate.format('MMM')}${showYear ? `'` + endDate.format('YY') : ''}`;
		}
	} catch (error) {

	}
	return text;
};

/**
 * update selected no of options of filter
 * params : current filter object, value of the filter
 */
export const updateCount = (filter, value) => {
	if (value === false && filter.selectedCount > 0) {
		filter.selectedCount--;
	} else if (value === true) {
		filter.selectedCount++;
	}
	if (filter.type === FILTER_CONSTANTS.DROPDOWN) {
		filter.selectedCount = value.length;
	}
	filter.showClear = filter.type === FILTER_CONSTANTS.CHECKBOX && filter.selectedCount > 0;
};

export const validAmount = (value) => {
	let val = value && value.split(',').join('');
	return val && !isNaN(Number(val));
};

export const validDays = (value) => value && !isNaN(Number(value)) && value < 365;

export const formatAmount = (value) => {
	let val = value.split(',').join('');
	return formatCurrency(val);
};

export const getOrgId = () => {
	let orgId = '';
	try {
		orgId = getProfileDataProps('orgId');
		const orgParam = urlParam('orgId');
		orgId = orgId || orgParam;
	} catch (error) {
		saveServerLogs(error.stack, 'error', 'getOrgId');
	}
	return orgId;
};

export const getSearchParams = () => {
	let searchParams = {};
	try {
		searchParams = getUrlParamsMap(window && window.location && window.location.search);
	} catch (error) {

	}
	return searchParams;
};

export const proceedToDashboard = () => {
	let orgId = getOrgId();
	let url = PATHS.DASHBOARD;
	if (orgId) {
		url = `${PATHS.DASHBOARD}?orgId=${orgId}`;
	}
	window.location.href = url;
};

export const proceedToExpenseLanding = () => {
	window.location.href = PATHS.EXPENSE_LANDING;
};

export const redirectTologinWithUrl = (url = typeof window != 'undefined' && window.location.pathname) => {
	const redirectUrl = encodeURIComponent(url);
	window.location.href = `${PATHS.LANDING_LOGIN_PAGE}&sessionExpiry=true&redirectUrl=${redirectUrl}`;
};

export const getShortDayText = (day) => {
	let strDay = String(day);
	const dayDgt = strDay.charAt(strDay.length - 1);
	const frstDgt = strDay.length > 1 ? strDay.charAt(0) : false;
	if (frstDgt && frstDgt == '1') {
		return `${day}th`;
	}
	switch (String(dayDgt)) {
		case '1':
			return `${day}st`;
		case '2':
			return `${day}nd`;
		case '3':
			return `${day}rd`;

		default:
			return `${day}th`;
	}
};

export const scrollOnWindow = (selector, extra) => {
	setTimeout(() => {
		if (selector) {
			const top = selector.offsetTop - extra;
			window.scrollTo({
				top: top,
				left: 0,
				behavior: 'smooth'
			});
		}
	});
};

export const setProfileData = (empDetails) => {
	const profileData = {};
	if (empDetails) {
		const { organizationId, name = '', id, businessEmailId, employeeStatus, phoneNumber, roles = [], gender, title, mmtUserId = '', mmtUuid = '' } = empDetails;
		profileData.name = name;
		profileData.id = id;
		profileData.email = businessEmailId;
		profileData.status = employeeStatus;
		profileData.phone = phoneNumber;
		profileData.gender = gender;
		profileData.firstLetter = name && name[0] ? name[0].toUpperCase() : '';
		profileData.title = title;
		profileData.isAdmin = roles && roles.length > 0 && roles.includes(ADMIN_ROLE);
		profileData.mmtUuid = mmtUuid;
		profileData.orgId = organizationId;

		try {
			globalData.setProperty('profileData', profileData);
			localStorage.setItem('mmtUserId', mmtUserId);
		} catch (error) {
			saveServerLogs(error.stack, 'error', 'setProfData');
		}
	}
	return profileData;
};

export const getProfileDataProps = (prop) => {
	try {
		const profData = globalData.getProperty('profileData');
		return profData?.[prop];
	} catch (err) {
		saveServerLogs(err.stack, 'error', 'getProfileDataProps');
		return false;
	}
};

export const isDashBoard = () => {
	let pathName = '';
	if (typeof window !== 'undefined' && window.location) {
		pathName = window.location.pathname;
	}
	return pathName === PATHS.DASHBOARD;
};

export const getPageUrl = () => {
	if (typeof window !== 'undefined' && window.location) {
		return window.location.pathname;
	} else {
		return false;
	}
};

export const getHashUrl = () => {
	if (typeof window !== 'undefined' && window.location) {
		return window.location.hash;
	} else {
		return false;
	}
};

export const capitalizeFirstLetter = (string) => {
	try {
		const str = string.toLowerCase();
		return str.charAt(0).toUpperCase() + str.slice(1);
	} catch (error) {
		return str;
	}
};

export const isLoggedIn = () => {
	let mmtAuth;
	try {
		mmtAuth = getCookie(APPLICATION_CONSTANTS.MMT_AUTH_COOKIE);
	} catch (err) {
		mmtAuth = false;
	}
	return !!mmtAuth;
};

export const checkLoggedIn = (path) => {
	const mmtauth = getCookie(APPLICATION_CONSTANTS.MMT_AUTH_COOKIE);
	if (!mmtauth && typeof window !== 'undefined') {
		let url = path;
		let params = typeof window !== 'undefined' && window.location ? window.location.search : '';
		url += params;
		redirectTologinWithUrl(url);
		saveServerLogs('login check failed no mmt-auth', 'error', path);
	}
	return mmtauth;
};

export const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

/**
 * Give concatenated class names string based on conditions
 * @param {(string|array[]|object)}  - Array can be array of array or array of string.
 * @return { String } classnames
 */
export const conditionalClassNames = (...args) => {
	const classes = [];
	for (let i = 0; i < args.length; i++) {
		const arg = args[i];
		if (!arg) continue;

		const argType = typeof arg;

		if (argType === 'string' || argType === 'number') {
			classes.push(arg);
		} else if (Array.isArray(arg)) {
			if (arg.length) {
				const inner = conditionalClassNames(arg);
				if (inner) {
					classes.push(inner);
				}
			}
		} else if (argType === 'object') {
			if (arg.toString !== Object.prototype.toString) {
				classes.push(arg.toString());
			} else {
				Object.keys(arg).forEach((key) => {
					if (arg[key]) {
						classes.push(key);
					}
				});
			}
		}
	}

	return classes.join(' ');
};

export const getFileType = (fileName) => {
	if (!fileName) {
		return '';
	}
	const fileArr = fileName.split('.');
	let ext = fileArr[fileArr.length - 1];
	ext = ext && ext.toLowerCase();
	switch (ext) {
		case 'png':
		case 'jpg':
		case 'jpeg':
			return FILE_TYPE.IMAGE;
		case 'pdf':
			return FILE_TYPE.PDF;
		case 'mp3':
			return FILE_TYPE.MUSIC;
		case 'csv':
			return FILE_TYPE.CSV;
		default:
			return '';
	}
};

export const setLocalStorage = (key, value, isSession = false) => {
	if (typeof window !== 'undefined' && window.localStorage && key) {
		try {
			if (isSession && window.sessionStorage) {
				sessionStorage.setItem(key, value);
			} else {
				localStorage.setItem(key, value);
			}
		} catch (err) {
			console.error(err);
		}
	}
};

export const getLocalStorage = (key, isSession = false) => {
	let value;
	try {
		if (typeof window !== 'undefined' && window.localStorage && key) {
			if (isSession && window.sessionStorage) {
				value = sessionStorage.getItem(key);
			} else {
				value = localStorage.getItem(key);
			}
		}
	} catch (err) {
		console.error(err);
	}

	return value;
};

export const removeLocalStorage = (key) => {
	if (window && window.localStorage && key) {
		try {
			localStorage.removeItem(key);
		} catch (err) {
			console.error(err);
		}
	}
};

export const isMobileContext = (context) => (context === 'mobile');

export const formatName = (name) => {
	if (!name) {
		return '';
	}
	let nameTokens = name.split(' ');
	let formattedName = '';
	for (let item in nameTokens) {
		if (nameTokens.hasOwnProperty(item)) {
			formattedName += nameTokens[item][0].toUpperCase() + nameTokens[item].toLowerCase().substring(1) + ' ';
		}
	}
	return formattedName.trim();
};

export const redirectTologin = () => {
	window.location.href = `${PATHS.LANDING_LOGIN_PAGE}`;
};

export const getDateObjectFromMillis = (millis) => {
	let date;
	try {
		date = millis ? new Date(millis) : new Date();
	} catch (err) {
		date = new Date();
	}
	return date;
};

export const numberFieldValidator = (value) => (value && String(value).replace(/[A-Za-z!@#$%^&*()+-]/g, '')) || '';

/**
 * debounce for functions like emp search
 * @author mybizFe
 * @param { Function } func to be executed after debounce timeout
 * @param { Array } wait api resp object
 * @param { Boolean } immediate if to execute debounce immediately
 * @return { Function } returns function to be executed
 */
export const debounce = (func, wait, immediate = false) => {
	let timeout;
	return function(...args) {
		const context = this;
		const later = () => {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export const decodeInviteCode = (code) => {
	let emailId = false;
	let inviteCode = false;
	if (code) {
		try {
			let decodedValue = atob(code);
			let decodedValueMap = {};
			let splitDecodedValue = decodedValue && decodedValue.split('&');
			if (splitDecodedValue) {
				for (let i = 0; i < splitDecodedValue.length; i++) {
					let splicedValue = splitDecodedValue[i].split('=');
					if (splicedValue.length > 1) {
						let key = splicedValue[0];
						let value = splicedValue[1];
						if (key && value) {
							decodedValueMap[key] = value;
						}
					}
				}
			}
			if (Object.keys(decodedValueMap).length > 0) {
				emailId = decodedValueMap['e'];
				inviteCode = decodedValueMap['c'];
			}
		} catch (e) {
			saveServerLogs('could not decode invitecode ' + code, 'error', '');
		}
	}

	return {
		emailId: emailId,
		inviteCode: inviteCode
	};
};
export const clearSessionStorage = () => {
	sessionStorage.clear();
};

export const convertTime = (timeSec) => {
	const hr = parseInt(timeSec / 3600);
	const leftMin = parseInt(timeSec % 3600);
	const min = parseInt(leftMin / 60);
	const sec = leftMin % 60;
	return {
		min, hr, sec
	};
};

export const getUploadRemainingTime = (config, callback) => {
	const serverDelayTime = 35;
	const timeStarted = getDateObjectFromMillis().getTime() - serverDelayTime;
	let remainingTime = 60 + serverDelayTime;

	const timeController = setInterval(function() {
		const { uploadedBytes, totalLength } = config;
		const timeDiff = ((getDateObjectFromMillis().getTime()) - timeStarted);
		const timeElapsed = timeDiff > 0 ? timeDiff : 1;
		// Upload Speed bytes/sec
		const uploadSpeed = uploadedBytes / (timeElapsed / 1000);
		if (totalLength <= uploadedBytes) {
			remainingTime = remainingTime - 1;
		} else {
			remainingTime = Math.round(((totalLength - uploadedBytes) / uploadSpeed) + serverDelayTime);
		}
		callback(convertTime(remainingTime));
		if (remainingTime <= 10) {
			clearInterval(timeController);
		}
	}, 1000);
	return timeController;
};

export const getCountText = (count, type, plural) => {
	let ret = '';
	if (count > 0) {
		ret = `${count} ${type}`;
		ret = count > 1 ? (plural ? `${count} ${plural}` : `${ret}s`) : ret;
	}
	return ret;
};

export const delay = (ms) => new Promise((resolve) => setTimeout(() => resolve(true), ms));

export function* sleep(time) {
	yield new Promise((resolve) => setTimeout(resolve, time));
}
export const validEmail = (value) => !email(value);

export const validName = (value) => !isValidName(value);

export const validPhone = (value, defVal) => {
	let phoneRegex = REGEX_PATTERNS.PHONE_REGEX;
	if (defVal && defVal === value) {
		phoneRegex = REGEX_PATTERNS.DEF_PHONE_REGEX;
	}
	return value && phoneRegex.test(value);
};

export const clearLoginState = () => {
	globalData.setProperty('profileData', false);
	setMmtAuthForCorpLogin('', -1);
};

export const setMmtAuthForCorpLogin = (authToken, expireTime, role, couchKey, xCSRFTOKEN, provCookie) => {
	setCookie('mmt-auth', authToken, expireTime, APPLICATION_CONSTANTS.MMT_COOKIE_DOMAIN);
};

export const checkRole = (role) => {
	for (let key in role) {
		if (role[key] === 'ADMIN') {
			return true;
		}
	}
	return false;
};

export const getIstTime = (inDate) => {
	try {
		let currDate = typeof inDate === 'object' ? inDate : new Date(inDate);
		const istTimezone = -330;
		const offset = currDate.getTimezoneOffset() - istTimezone;
		if (offset !== 0) {
			const currTime = currDate.getTime();
			currDate.setTime(currTime + (offset * 60 * 1000));
		}
		return currDate;
	} catch (error) {
		return false;
	}
};

export const redirectToResourcePage = () => {
	window.location.href = `${PATHS.RESOURCE_PAGE}`;
};

/**
 * format employee data from api for UI render
 * @author mybizFe
 * @param { Array } employees api resp object
 * @return { Array } formatted employee object
 */
export const formatEmployeesData = (employees = []) => {
	const formattedData = employees.map((employee) => {
		const { businessEmailId, name } = employee;
		return ({
			id: businessEmailId,
			name,
			label: businessEmailId,
			value: businessEmailId,
			subText: name ? ` (${name})` : ''
		});
	});
	return formattedData;
};

/**
 * utility functions to add script tag
 * @author mybizFe
 * @param { String } src script scourcee url
 * @param { Boolean } async async script
 * @param { Boolean } defer defer script
 * @param { Function } onLoad callback on load of script
 */
export const addScript = (src, async = !0, defer = !0, onLoad = () => { }) => {
	if (src) {
		const newScript = document.createElement('script');
		newScript.type = 'text/javascript';
		newScript.async = async;
		newScript.defer = defer;
		newScript.src = src;
		newScript.onload = onLoad;
		document.getElementsByTagName('head')[0].appendChild(newScript);
	}
};

/**
 * utility function to add 's' behind text
 * @author mybizFe
 * @param { String } text word to get Plural text
 * @param { Number } count total number of persons
 * @return { String } returns plural text
 */
export const getPluralText = (text, count) => `${text}${count > 1 ? 's' : ''}`;

/**
 * check the form error state on input change
 * @author mybizFe
 * @param { Array }  formFields
 * @return { String } returns disabled state/error
 */
export const handleChangeInput = (formFields = []) => {
	let error = false;
	const fieldLen = formFields.length;
	for (let i = 0; i < fieldLen; i++) {
		const currFld = formFields[i] || {};
		error = (!currFld.value && currFld?.validation?.required) || currFld.error;
		if (error) {
			break;
		}
	}
	return !!error;
};

/**
 * check the form error state on input change
 * @author mybizFe
 * @param { Array }  formFields
 * @return { String } returns disabled state/error
 */
export const getGoogleMapsAutoComplete = (searchVal) =>{
	if (window?.google?.maps?.places?.AutocompleteService) {
		const service = new window.google.maps.places.AutocompleteService();
		return new Promise((resolve, reject) => {
			service.getPlacePredictions({ input: searchVal, componentRestrictions: { country: 'in' } },
				(result, status) => {
					if (status === 'OK') {
						resolve({ data: { predictions: result } });
					} else {
						reject(result);
					}
				});
		});
	}
	return Promise.reject('Google API Not loaded');
};

/**
 * function to get request data from google map api
 * @author mybizFe
 * @param { request }  request api request url
 * @return { String } returns promise object
 */
export const getGoogleMapsGeocode = (request) =>{
	if (window?.google?.maps?.Geocoder) {
		const service = new window.google.maps.Geocoder();
		return new Promise((resolve, reject) => {
			service.geocode({ placeId: request },
				(result, status) => {
					if (status === 'OK') {
						resolve({ data: { results: result, status } });
					} else {
						reject(result);
					}
				});
		});
	}
	return Promise.reject('Google API Not loaded');
};

/**
 * returns latitude and longitude for the selected Google city
 * @author mybizFe
 * @param { object }  response
 * @return { object } returns latitude and longitude for the selected Google city
 */
export const getSearchCityGooglePoiResponse = (response) =>{
	const responseBody = response.data;
	let updatedResponse = null;
	if (responseBody && responseBody.results && responseBody.status === 'OK') {
		const { address_components, geometry = {} } = responseBody.results[0];
		const { location } = geometry;
		updatedResponse = {
			lat: typeof location.lat === 'function' ? location.lat() : location.lat,
			lng: typeof location.lng === 'function' ? location.lng() : location.lng,
			address_components
		};
	}
	return updatedResponse;
};

/**
 * checks whether valid NGO Id or not
 * @author mmt9855
 * @param { string }  value
 * @return { boolean }
 */
export const isValidNGOId = (value) => value && REGEX_PATTERNS.NGO_ID_REGEX.test(value.trim());

/**
 * checks whether valid Udyam Number or not
 * @author mmt9855
 * @param { string }  response
 * @return { boolean }
 */
export const isValidUdyamNumber = (value) => value && REGEX_PATTERNS.UDYAM_REGEX.test(value.trim());

/**
 * updates object after uploading file
 * @author mmt98555
 * @param { string }  response
 * @return { object }
 */
export const fileUploadHandler = (filesData = {}, fileIdentifier, actionFileState)=>{
	let filesDataClone = cloneDeep(filesData);

	delete filesDataClone[fileIdentifier];
	filesDataClone = {
		[fileIdentifier]: {
			...filesData[fileIdentifier],
			...actionFileState,
			uploadStatus: possibleUploadStates.done
		},
		...filesData
	};
	delete filesDataClone.emptyUploaderObject;
	return filesDataClone;
};

/**
 * updates object after deleting image upload
 * @author mmt98555
 * @param { string }  deletedFileName
 * @return { object }
 */
export const fileDeleteHandler = (filesData = {}, deletedFileName)=>{
	let filesDataClone = cloneDeep(filesData);
	filesDataClone[deletedFileName].deleteStatus = possibleUploadStates.loading;

	delete filesDataClone[deletedFileName];
	const filesDataLength = Object.keys(filesDataClone).length;
	if (filesDataLength === 0) {
		filesDataClone = { ...fileUploadConfig.initState };
	}
	return filesDataClone;
};

/**
 * get tool tip style config for hover icons
 * @author mmt8054
 * @param { String } type of table
 * @return { Object } customconfig
 */
export const getIconHoverConfig = (type) => {
	const toolTipConfig = {
		$width: '610px',
		posTop: '-20px',
		posLeft: '16px',
		left: '75%',
		transform: 'translate(-75%,-100%)',
		bg_color: '#fff',
		color: '#000',
		zIndex: '2',
		padding: '0',
		boxShadow: '0 3px 10px 0 rgb(0 0 0 / 10%)'
	};
	const customise = {};
	switch (type) {
		case 'infotooltip':
			customise['$width'] = '200px';
			customise['transform'] = 'translate(-75%,55px)';
			customise['top_arr'] = true;
			break;
		case 'hotelpolicy':
			customise['$width'] = '475px';
			customise['posTop'] = '70px';
			customise['posLeft'] = '-130px';
			customise['transform'] = 'translate(0,0)';
			customise['no_arr'] = true;
			break;
		case 'priceRise':
			customise['$width'] = '300px';
			customise['posTop'] = '170px';
			customise['posLeft'] = '250px';
			customise['no_arr'] = true;
			break;
		case 'cabpolicy':
			customise['btm_arr'] = true;
			break;
		case 'cheaperRecommendations':
			customise['$width'] = '400px';
			customise['top_arr'] = true;
			customise['posTop'] = '200px';
			break;
	}
	return {
		...toolTipConfig,
		...customise
	};
};

/**
 * validate if url is of mmt domain or relative url
 * @author mmt8270
 * @param { String } url url to be validated
 * @return { Boolean }
 */
export const isValidUrl = (url) => {
	try {
		return REGEX_PATTERNS.MMT_DOMAIN_URLS.test(decodeURIComponent(url));
	} catch (error) {
		saveServerLogs(error.stack, 'error', `isValidUrl:${url}`);
		return false;
	}
};

export const setSessionStorage = (key, value) => {
	if (typeof window !== 'undefined' && window.sessionStorage && key) {
		try {
			sessionStorage.setItem(key, value);
		} catch (err) {
			console.error(err);
		}
	}
};

export const getSessionStorage = (key) => {
	let value;
	try {
		if (typeof window !== 'undefined' && window.sessionStorage && key) {
			value = sessionStorage.getItem(key);
		}
	} catch (err) {
		console.error(err);
	}
	return value;
};

export const getXrequestId = () => {
	if (typeof window !== 'undefined') {
		return globalData.getProperty('xRequestId') || globalData.setProperty('xRequestId', window.xRequestId || getReqID());
	}
	if (typeof global !== 'undefined') {
		return global.xreqId || (global.xreqId = getReqID());
	}
	return '';
};

/**
 * Polls the job status using the provided API request and callbacks.
 *
 * @param {Object} apiRequest - The API request object.
 * @param {Function} successCb - The callback function to be called on successful response.
 * @param {Function} failureCb - The callback function to be called on failure or error.
 */
export const pollJobStatus = async (apiRequest, successCb, failureCb) => {
	let response = null;
	const url = PATHS.POLL_JOB_STATUS;
	try {
		response = await ccbAxiosApiCaller.post(url, apiRequest);
		if (response.data?.data) {
			successCb(response.data?.data);
		} else if (response.data?.retry) {
			setTimeout(() => {
				pollJobStatus(apiRequest, successCb, failureCb);
			}, (response.data.retryDelay || APPLICATION_CONSTANTS.POLL_INTERVAL));
		} else {
			failureCb();
		}
	} catch (e) {
		failureCb();
		saveServerLogs(e.stack + JSON.stringify(apiRequest), 'error', 'pollJobStatus');
		return;
	}
};

export function shouldForwardProp(propName, target) {
	if (typeof target === 'string') {
		// For HTML elements, forward the prop if it is a valid HTML attribute
		return isPropValid(propName);
	}
	// For other elements, forward all props
	return true;
}

/**
 * IsNumeric
 *
 * @author - MMT11933
 * @description - Checks if the given value is numeric.
 * The function uses a regular expression to determine if the input is composed only of digits.
 * This means it checks for an integer value without any decimal points or other characters.
 *
 * @param {string} value - The value to be checked.
 * @return {boolean} Returns true if the value is numeric (consisting only of digits), false otherwise.
 */
export const isNumeric = (value) => REGEX_PATTERNS.NUMERIC_ONLY_REGEX.test(value);

/**
 * IsAlphaNumeric
 *
 * @author - MMT11933
 * @description - Checks if the given value is alphanumeric.
 * The function uses a regular expression to determine if the input consists of both letters and numbers.
 * This means it checks for a string containing only characters from the English alphabet (both uppercase and lowercase)
 * and digits, without any special characters or spaces.
 *
 * @param {string} value - The value to be checked.
 * @return {boolean} Returns true if the value is alphanumeric, false otherwise.
 */
export const isAlphaNumeric = (value) => REGEX_PATTERNS.ALPHANUMERIC_REGEX.test(value);

export const evaluateString = (obj) => new Function(`return (${obj})`)();

export const deepMerge = (obj1, obj2) => {
	const isObject = (obj) => obj && typeof obj === 'object';

	if (!isObject(obj1) || !isObject(obj2)) {
		return obj2;
	}
	Object.keys(obj2).forEach((key) => {
		const obj1Value = obj1[key];
		const obj2Value = obj2[key];
		if (Array.isArray(obj1Value) && Array.isArray(obj2Value)) {
			obj1[key] = obj1Value.concat(obj2Value);
		} else if (isObject(obj1Value) && isObject(obj2Value)) {
			if (Array.isArray(obj1Value) || Array.isArray(obj2Value)) {
				obj1[key] = obj2Value;
			} else {
				obj1[key] = deepMerge(Object.assign({}, obj1Value), obj2Value);
			}
		} else {
			obj1[key] = obj2Value;
		}
	});

	return obj1;
};
