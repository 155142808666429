function globalData() {
	if (globalData._instance) {
		return globalData._instance;
	}
	if (!(this instanceof globalData)) {
		return new globalData();
	}
	globalData._instance = this;

	const savedData = {
		test: 1
	};

	this.setProperty = (prop, value) => {
		if (typeof prop === 'string' || typeof prop === 'number') {
			savedData[prop] = value;
			return value;
		} else throw Error(`property type should be string or number: recieved ${typeof prop}`);
	};

	this.getProperty = (prop) => savedData[prop];
}

// export const globalDataFactory = globalData;
const globalInstance = globalData();
Object.freeze(globalInstance);
module.exports = globalInstance;
